import React from 'react'

const Two = () => {
  return (
    <div>
     <iframe
        title="External Content"
        src="https://b2-qmk2-ragop1.vercel.app/"
        style={{ width: '100%', height: '100vh', border: 'none' }}
      />
    </div>
  )
}

export default Two